.modal-device {
    width: 470px !important;

   &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;  
    color: #443E3E;
   }

    &__display_contents {
        display: contents !important;
    }
    &__row {
        background-color: rgb(247, 247, 247);
    border-radius: 5px;
    width: 415px;
    height: 75px;
    padding-right: 15px;
    padding-left: 10px;
    
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
border-radius: 10px;
    }
    &__icon {
        width: 60px;
        height: 48px;
    }
    
}
.device-info {
    display: flex;
    flex-direction: column;
    margin-left: -55px;
    &__id {
        font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;


letter-spacing: 0.2px;

color: #000000;
    }
    &__battery {
        font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;


letter-spacing: 0.2px;
color: #CCCCCC !important;
    }
}