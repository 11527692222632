.header {
  background: #006674;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px;
  width: 100%;
  height: 87px;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  &__nickname {
    font-size: 15px;
    line-height: 18px;
    margin-left: 10px;
    color: #FFFEFE;
    text-decoration: none;
  }
}

.logo {
  width: 46px;
  height: 43px;

  &-title {
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #FFFEFE;
    margin-left: 8px;
  }
}