.profile {
  background: url('../../assets/images/profile-bg.svg') no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &__form {
    width: 900px;
    padding: 20px 80px;

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
    }

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #443E3E;
      margin-bottom: 16px;
      text-align: left;
      font-family: Poppins, sans-serif;
    }

    &-input {
      border: 1px solid #CCCCCC;
      border-radius: 15px;
      outline: none;
      padding: 12px 32px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #443E3E;
    }

    &-input::-webkit-outer-spin-button,
    &-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &-input[type=number] {
      -moz-appearance: textfield;
    }

    &-right,
    &-left{
      width: 340px;
    }

    &-btn {
      text-decoration: none;
      width: 234px;
      padding: 12px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      border: none;
      background: #1BBDD4;
      border-radius: 10px;
      color: #fff;
      margin:  20px auto 0;
      display: block;
      cursor: pointer;
    }
  }
}

.container-profile {
  width: 100%;
    height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}