.title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #443e3e;
}

.file-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    z-index: -1;
}

.file-input-label {
    background-color: #1bbdd4;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

/* Additional styling for hover, focus, active states if desired */
.file-input-label:hover {
    background-color: #15a5bf;
}

.main {
    display: flex;
    align-items: center;
    flex-direction: column;
}

progress {
    width: 40%;
    height: 35px;
    margin-top: 20px;
}
