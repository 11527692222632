.results {
  display: flex;
  flex-direction: column;
  padding: 32px 0 0 32px;

  &__list {
    display: flex;
    flex-direction: column;

    &_blocks {
      display: flex;
      margin-bottom: 30px;
      &_title {
        margin-left: 100px;
      }
      &_category {
        margin-left: 190px;
      }
      &_date {
        margin-left: 130px;
      }
      &_percent {
        margin-left: 50px;
      }
      & > p {
        color: #443E3E;
        font-size: 18px;
      }
    }

    &_img {
      background: url("./../../assets/images/download.svg") no-repeat center;
      width: 19px;
      height: 24px;
      padding: 22px 20px;
      border-radius: 7px;
      border: 1px solid #CCCCCC;
      margin-right: 10px;
      cursor: pointer;
    }

    &_btn {
      border-radius: 7px;
      padding: 10px 10px;
      margin-right: 10px;
      font-size: 18px;
      cursor: pointer;
      width: 123px;

      &:last-child {
        margin-right: 0;
      }
    }

    &_name {
      width: 317px;
    }

    &_title {
      width: 255px;
    }

    &_date {
      width: 105px;
      text-align: center;
    }

    &_elem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      & > p {
        color: #443E3E;
        padding: 10px;
        font-size: 15px;
        border: 1px solid #CCCCCC;
        border-radius: 7px;
        margin-right: 10px;
      }
    }
  }
}

.test-result-wrap {
  width: 100%;
  align-self: flex-start;
  padding: 50px 24px;
  // text-align: center;
}

.result-title {
  font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #443E3E;
font-family: 'Poppins', sans-serif;
}