.test-list-wrap {
    width: 100%;
    align-self: flex-start;
    padding: 50px 24px;
}

.test-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #443E3E;
}