@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  height: 100vh;
}

input {
  font-family: Poppins, sans-serif;
}

#root {
  height: 100%;
}

.layout-wrap {
  margin-left: 295px;
  height: calc(100% - 90px);
  display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.inner-container {
  padding: 24px;
}

.title {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #006674;
  margin: 0 auto 30px auto;
}

.btn-white {
  background: #FFFFFF;
  border-radius: 10px;
  color: #1BBDD4;
  border: 1px solid #1BBDD4;
  transition: all .3s;

  &:hover {
    background-color: #1BBDD4;
    color: #FFFFFF;
  }
}

.btn-danger {
  background: #FF6A6D;
  border-radius: 10px;
  color: #FFFFFF;
  border: 1px solid #FF6A6D;
  transition: all .3s;

  &:hover {
    background-color: #FF6A6D;
    color: #FFFFFF;
  }
}

.danger {
  background-color: #FF6A6D;
  border: 1px solid #FF6A6D;
  color: #FFFFFF;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(183, 30, 38, 0.8);
  }
}

.btn-blue {
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #1BBDD4;
  color: #1BBDD4;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(27,189,212,0.5);
  }
}

.delete-blue {
  background: #1BBDD4;
  border-radius: 10px;
  border: 1px solid #1BBDD4;
  color: #FFFFFF;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 5px 3px rgba(27,189,212,0.5);
  }
}
