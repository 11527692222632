.test {
  background: url("../../assets/images/profile-bg.svg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 75px);
  width: 100%;
  color: #000;
  overflow: hidden;
  &--finish {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #1998a7;
  }
  &--heading {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #443e3e;
    margin-bottom: 50px;
  }
  &--description {
    width: 680px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    /* grey5 */

    color: #443e3e;
  }
}

.full-test {
  width: 100vw;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}

.fullTest {
  color: #000;
  padding: 20px;
  text-align: center;

  &--title {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;

    color: #443e3e;
  }
  &--description {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #443e3e;
    margin: 10px auto 10px auto;
    width: 700px;
  }
  &__test {
    &--duration {
      display: flex;
      justify-content: space-between;
    }
  }
}
