.show {
  display: flex;
  justify-content: center;
  word-break: break-all;
  align-items: center;
  position: fixed;
  height: 100vh;
  left: 580px;
  right: 0;
  top: 87px;

  &__box {
    z-index: 22;
    padding: 32px 50px 50px 50px;
    width: 100%;
    //max-width: 885px;
    max-width: 998px;
    min-width: 786px;
    height: max-content;
    max-height: 90%;
    background-color: #FFFFFF;
    overflow-y: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #443E3E;
    overflow-x: hidden !important;
    transform: translate(-150px, -50px);

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }
    &_top img { 
      align-self: flex-start;
    }
    
  }
  

  &__title {
    font-weight: normal;
    font-size: 18px;
    margin-left: 13px;
    width: 100%;
    padding-right: 26px;
  }

  &__text {
    width: 100%;
    max-width: 885px;
    font-size: 16px;
    line-height: 24px;
    margin-left: 26px;
    color: #443E3E;
    padding-right: 26px;
  }

  &__percent {
    color: #006674;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
  }

  &__bar {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: 2px solid #EEEEEE;
    margin-bottom: 16px;
  }

  &__effected {
    width: 785px;
    display: flex;
    justify-content: space-between;

    &_text {
      font-size: 18px;
    }
  }

  &-bg {
    position: fixed;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(8px);
    z-index: 12;
  }
}

.row-container-centered {
  background: #FFFFFF;
  border: 2px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 5px;
  width: 88%;
  height: 20px;
  position: relative;
  &__positive-value {
    background: #088496;
    box-shadow: 5px 0px 10px rgb(7 127 145 / 20%);
    border-radius: 0px 5px 5px 0px;
    height: 18px;
    //width: 45%;
    max-width: 50%;
    position: absolute;
    left: 50%;
    min-width: 14%;
  }
  &__negative-value {
    background: #088496;
    box-shadow: 5px 0px 10px rgb(7 127 145 / 20%);
    border-radius: 5px 0px 0px 5px;
    height: 18px;
    //width: 45%;
    max-width: 50%;
    position: absolute;
    right: 50%;
    min-width: 14%;
  }
}
.info {
  margin-left: 11%;
  width: 88%;
  margin-bottom: 4px;
}

.result-description {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 11%;
  min-width: 80px;
}

.values_positive {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  position: absolute;
  right: 6px;
  bottom: -4px;
}
.values_negative {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  position: absolute;
  left: 6px;
  bottom: -4px;
}

.color-primary {
  background: #088496;
}
.color-secondary {
  background: #1BBDD4;
}
.color-red {
  background: #FF6A6D;
}

.stimuli-type-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #006674;
  margin-bottom: 10px;
  margin-top: 34px;
}

.d-primary {
  color: #088496;
}

.d-secondary {
  color: #1BBDD4;
}

.d-red {
  color: #FF6A6D;
}

.row-container-left {
  box-shadow: 5px 0px 10px rgb(7 127 145 / 20%);
  border-radius: 0px 5px 5px 0px;
  height: 18px;
  //width: 45%;
  max-width: 100% !important;
  position: relative;
  min-width: 14%;
}

.center-point {
  position: absolute;
  right: 45%;
}


.start-point {
  position: absolute;
  left: 15%;
}

.closeIcon {
  cursor: pointer;
}

.profile {
  // background: url('../../assets/images/profile-bg.svg') no-repeat;
  background-size: cover;
  width: calc(100% - 255px);
  height: calc(100vh - 91px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__form {
    width: 900px;
    padding: 40px 40px 20px 40px;

    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-inputs {
      display: block;
      width: 100%;
      margin-bottom: 24px;
    }

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #443E3E;
      margin-bottom: 16px;
    }

    &-input {
      border: 1px solid #CCCCCC;
      border-radius: 15px;
      outline: none;
      padding: 12px 32px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #443E3E;
    }

    &-input::-webkit-outer-spin-button,
    &-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &-input[type=number] {
      -moz-appearance: textfield;
    }

    &-right,
    &-left{
      width: 362px;
    }

    &-btn {
      width: 234px;
      padding: 12px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      border: none;
      background: #1BBDD4;
      border-radius: 100px;
      color: #fff;
      margin: 0 auto;
      margin-top: 50px;
      display: block;

    }
  }
}

.test-info {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.test__form-input {
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 15px;
  outline: none;
  padding: 12px 32px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #443E3E;
}