.AF3 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 67px;
  left: 75px;
}

.F7 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 77px;
  left: 33px;
}

.T7 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 177px;
  left: 36px;
}

.FC5 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 116px;
  left: 45px;
}

.F3 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 110px;
  left: 85px;
}

.P7 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  bottom: 53px;
  left: 54px;
}

.O1 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  bottom: 16px;
  left: 75px;
}

.O2 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  bottom: 16px;
  right: 75px;
}

.P8 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  bottom: 53px;
  right: 54px;
}

.T8 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 177px;
  right: 36px;
}

.FC6 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 116px;
  right: 45px;
}

.F8 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 77px;
  right: 33px;
}

.F4 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 110px;
  right: 85px;
}

.AF4 {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  position: absolute;
  top: 67px;
  right: 75px;
}

.red {
  background-color: #f04438;
  transition: 500ms;
}
.green {
  background-color: #add57f;
  transition: 500ms;
}

.yellow {
  background-color: #f9ed37;
  transition: 500ms;
}
.container {
  width: max-content;
  height: max-content;
  position: relative;
  margin-left: 20px;
  margin-right: 0;
}

.headset-point-left {
  top: 147px;
  left: 16px;
  position: absolute;
}

.headset-point-right {
  top: 147px;
  right: 16px;
  position: absolute;
}
.OVERALL {
  bottom: -90px;
  position: absolute;
  left: 90px;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #add57f;
}

.available_button {
  width: 222px;
  height: 41px;
  border: 2px solid #1bbdd4;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1bbdd4;
  position: absolute;
  cursor: pointer;
  top: 60px;
  left: 47px;
}
.available_button:hover {
  cursor: pointer;
  transition: 100ms;
  transform: scale(1.005);
}

.headset_status_wrap {
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: calc(100vw - 300px);
  &_row {
    justify-content: center;
    // margin-top: 10%;
  }
}

.connect_quality {
  display: flex;
  flex-direction: column;
  //   width: 40%;
  margin-left: 10%;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: #443e3e;
    text-align: left;
  }
  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 100px;
    color: #999999;
  }
  &_button {
    margin-top: 100px;
  }
}

.fadeOut.end{
  transition: all 1s ease-in-out;
  background-color: rgba(255, 0, 0, 0.0);
  box-shadow: none;
  border: 0px dashed yellow;
  border-radius: 0px;
}


.fade-out {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}