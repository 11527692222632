.passing {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  // width: calc(100vw - 313px);
  padding-top: 50px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;

  &__description {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #443E3E;
    width: 660px;
    margin-bottom: 10px;
  }

  &__level {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    // padding-left: 24px;
    // padding-right: 24px;

    & > span {
      font-size: 20px;
      line-height: 30px;
      color: #443E3E;
    }
  }

  &__content {
    position: relative;

    & > img {
      height: 400px;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: #1998A7;
    border-radius: 50%;
    cursor: pointer;

    &-inactive {
      cursor: not-allowed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      background-color: #F3F3F3;
      border-radius: 50%;
    }
  }
}

.before {
  position: absolute;
  left: -60px;
  top: 45%;
  transform: rotate(-90deg);
}

.next {
  position: absolute;
  right: -60px;
  top: 45%;
  transform: rotate(90deg);
}

.test-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
border-radius: 20px;
-webkit-border-radius: 20px;
-moz-border-radius: 20px;

background-image: url('../../assets/images/background_test_2.svg');
background-repeat: no-repeat, repeat;
background-position: center;
background-size: 100vw;
}

.background-filter::after {
  -webkit-backdrop-filter: blur(10px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  backdrop-filter: blur(10px); /* Supported in Chrome 76 */
  filter: grayscale(.8); 
  content: "";
  display: block;
  position: absolute;
  width: 100%; height: 100%;
  top: 0;
  border-radius: 20px;
}

.background-filter {
  position: relative;
}

.not-found {
  background-image: url('../../assets/images/background_test_notfound.svg');
}

.u-non-blurred {
  position: relative;
  z-index: 1;
}
// .slide {
//   max-width: 75% !important;
//   max-height: 100%;
// }


.slide {
  height: 97%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-top: 50px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}